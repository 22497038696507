import React, { useState } from 'react'

import test from './test.json';
import Header from './Header';

function Test() {
	const [images, setImages] = useState(test)

	async function separateImages(e) {
		const array = [];
		for (let i = 0; i < e.target.files.length; i++) {
			const item = e.target.files[i]
			if (item.name.includes('a')) {
				console.log('------------------------------------------------------------------------------------------------------------');
				var name = item.name.replaceAll('a', '').replaceAll('i', '').replaceAll('.jpg', '')
				var biletNumber = name.split('_')[0]
				var questionNumber = name.split('_')[1]
				array.push({
					id: Number(biletNumber) * 10 + Number(questionNumber) - 10,
					bilet_number: biletNumber,
					new_image: item.name,
					old_image: item.name.replaceAll('a', '')
				})
				// var question = data.find(el => el.image_url === item.name.replaceAll('a', '').replaceAll('i', ''))
				// console.log(question);
			}
		}

		setImages(array)
	}

	async function addNewImageUrls(e) {
		const imagesCopy = [...images];

		for (let i = 0; i < imagesCopy.length; i++) {
			imagesCopy[i]['image_url'] = "";
		}

		for (let i = 0; i < e.target.files.length; i++) {
			const item = e.target.files[i]
			if (!item.name.includes('a')) {
				var name = item.name.replaceAll('i', '').replaceAll('.jpg', '')
				var row_number = Number(name.split('_')[0]) * 10 + Number(name.split('_')[1]) - 10


				if (row_number > 70) {
					var index = imagesCopy.findIndex(item => item.row_number === row_number)
					if (index >= 0) {
						console.log(row_number);
						imagesCopy[index]['image_url'] = item.name;
					}
				}
			}
		}

		setImages(imagesCopy)
	}

	const downloadJSON = () => {
		const dataStr = JSON.stringify(images, null, 2);
		const blob = new Blob([dataStr], { type: "application/json" });
		const url = URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = url;
		link.download = 'new_images.json';
		link.click();

		URL.revokeObjectURL(url);
	};

	return (
		<>
			<div className="container">
				<Header />
				<div className="text-content">
					{/* <input type="file" className="form-control" multiple onChange={(e) => separateImages(e)} /> */}
					<input type="file" className="form-control" multiple onChange={(e) => addNewImageUrls(e)} />

					<button className="btn btn-primary my-2 w-100" onClick={downloadJSON}>Download JSON</button>

					{images.map((item, index) => (
						<div key={index}>
							{item.image_url}
						</div>
					))
					}
				</div>
			</div>
		</>
	)
}

export default Test