import React, { useState } from 'react'

import questions from './test.json';
import Header from './Header';
import axios from 'axios';
import { toast } from 'react-toastify';
function Test() {
	const [comments, setComments] = useState(questions.filter(item => item.bilet_number > 70))

	function startTimer() {
		console.log(111);

		setTimeout(() => {
			console.log(222);
			translate()
		}, 36000);
	}

	async function translate() {
		var key = 'comment_uz_cyrl'
		var secondKey = 'comment_uz_latn'
		var commentsCopy = [...comments]
		for (let i = 0; i < commentsCopy.length; i++) {
			if (!commentsCopy[i][key]) {
				console.log(commentsCopy[i]['row_number']);
				if (commentsCopy[i][secondKey]) {
					try {
						const response = await axios.post('https://from-to.uz/api/v1/transliterate', {
							"body": {
								"lang_from": "uz_latin",
								"lang_to": "uz_cyrillic",
								"text": commentsCopy[i][secondKey]
							}
						})

						if (response.status === 200) {
							commentsCopy[i][key] = response.data.result;
						}
					} catch (error) {
						console.log('catch');
						console.log(commentsCopy);
						console.log(commentsCopy.filter(item => !item[key]).length);
						setComments(commentsCopy);
						startTimer()
						return
					}
				} else {
					commentsCopy[i][key] = "";
				}
			}
		}
		setComments(commentsCopy);
		toast.success('Complete')
	}

	const downloadJSON = () => {
		const dataStr = JSON.stringify(comments, null, 2);
		const blob = new Blob([dataStr], { type: "application/json" });
		const url = URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = url;
		link.download = 'comments_cyrl.json';
		link.click();

		URL.revokeObjectURL(url);
	};

	return (
		<>
			<div className="container">
				<Header />

				<div className="text-content">

					<button className="btn btn-primary my-2 w-100" onClick={() => translate()}>Translate</button>

					<button className="btn btn-primary my-2 w-100" onClick={() => downloadJSON()}>Download JSON</button>

					<div style={{ overflowY: 'auto', height: '70vh' }}>
						{comments.map((item, index) => (
							<div key={index} className="my-2">
								{item.row_number} ({item.bilet_number})
								<b> {item.question_uz_cyrl}</b> <br /> <br />
								a. {item.answer_a_uz_cyrl} <br />
								b. {item.answer_b_uz_cyrl} <br />
								c. {item.answer_c_uz_cyrl} <br />
								d. {item.answer_d_uz_cyrl} <br />
								f. {item.answer_f_uz_cyrl} <br /> <br />
								{item.comment_uz_cyrl}
							</div>
						))
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default Test