import React from 'react'

import TestUz from '../pages/test_pdd/TestUz'
import TestRu from '../pages/test_pdd/TestRu'
import TestImage from '../pages/test_pdd/TestImage'
import TestCyrl from '../pages/test_pdd/TestCyrl'
import Comment from '../pages/test_pdd/Comment'
import CyrlJson from '../pages/test_pdd/CyrlJson'

import Login from '../pages/auth/Login'
import ForgotPassword from "../pages/auth/ForgotPassword"

// Accountant
import AccountantDailyReport from '../pages/accountant/DailyReport'
import AccountantBalanceReport from '../pages/accountant/BalanceReport'

// Reports
import ShiftReport from '../pages/director/report/ShiftReport'

// ADMIN
import AdminUser from '../pages/admin/users/Index'
import AdminPos from '../pages/admin/pos/Index'
import AdminAccountant from '../pages/admin/accountant/Index'
import AdminDirector from '../pages/admin/director/Index'
import AdminCashier from '../pages/admin/cashier/Index'

const publicRoutes = [
	{ path: "/test-uz", component: <TestUz /> },
	{ path: "/test-ru", component: <TestRu /> },
	{ path: "/test-cyrl", component: <TestCyrl /> },
	{ path: "/test-image", component: <TestImage /> },
	{ path: "/test-comment", component: <Comment /> },
	{ path: "/test-json", component: <CyrlJson /> },

	{ path: "/auth/login", component: <Login /> },
	{ path: "/auth/forgot-password", component: <ForgotPassword /> },
]

const cashierRoutes = [
	{ path: "/shift-report", component: <ShiftReport /> },
	{ path: "/shift-report/:id", component: <ShiftReport /> },
]

const accountantPrivateRoutes = [
	{ path: "/accountant/daily-report", component: <AccountantDailyReport /> },
	{ path: "/accountant/balance-report", component: <AccountantBalanceReport /> },
]

const adminPrivateRoutes = [
	// ADMIN
	{ path: "/admin/users", component: <AdminUser /> },
	{ path: "/admin/poses", component: <AdminPos /> },
	{ path: "/admin/accountant", component: <AdminAccountant /> },
	{ path: "/admin/director", component: <AdminDirector /> },
	{ path: "/admin/cashier", component: <AdminCashier /> },
]

export { publicRoutes, accountantPrivateRoutes, adminPrivateRoutes, cashierRoutes }