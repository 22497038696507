import React, { useState } from 'react'

import latnJson from './uz-Latn.json';
import Header from './Header';
import axios from 'axios';
import { toast } from 'react-toastify';
function Test() {
	const [translateJson, setTranslateJson] = useState(latnJson)
	const [translatedKeys, setTranslatedKeys] = useState([])

	function startTimer() {
		setTimeout(() => {
			translate()
		}, 36000);
	}

	async function translate() {

		var translateJsonCopy = { ...translateJson }
		var translatedKeysCopy = [...translatedKeys]
		for (var key in translateJsonCopy) {
			console.log(translatedKeysCopy);
			console.log(translatedKeysCopy.findIndex((k) => k === key) === -1);

			if (translatedKeysCopy.findIndex((k) => k === key) === -1) {
				try {
					const response = await axios.post('https://from-to.uz/api/v1/transliterate', {
						"body": {
							"lang_from": "uz_latin",
							"lang_to": "uz_cyrillic",
							"text": translateJsonCopy[key]
						}
					})

					if (response.status === 200) {
						translateJsonCopy[key] = response.data.result;
						translatedKeysCopy.push(key)
					}
				} catch (error) {
					setTranslateJson(translateJsonCopy);
					setTranslatedKeys(translatedKeysCopy);
					startTimer()
					return
				}
			}
		}
		setTranslateJson(translateJsonCopy);
		setTranslatedKeys(translatedKeysCopy);
		toast.success('Complete')
	}

	const downloadJSON = () => {
		const dataStr = JSON.stringify(translateJson, null, 2);
		const blob = new Blob([dataStr], { type: "application/json" });
		const url = URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = url;
		link.download = 'translated_cyrl.json';
		link.click();

		URL.revokeObjectURL(url);
	};

	return (
		<>
			<div className="container">
				<Header />

				<div className="text-content">

					<button className="btn btn-primary my-2 w-100" onClick={() => translate()}>Translate</button>

					<button className="btn btn-primary my-2 w-100" onClick={() => downloadJSON()}>Download JSON</button>

					<div style={{ overflowY: 'auto', height: '70vh' }}>
						{/* {comments.map((item, index) => (
							<div key={index} className="my-2">
								{item.row_number} ({item.bilet_number})
								<b> {item.question_uz_cyrl}</b> <br /> <br />
								a. {item.answer_a_uz_cyrl} <br />
								b. {item.answer_b_uz_cyrl} <br />
								c. {item.answer_c_uz_cyrl} <br />
								d. {item.answer_d_uz_cyrl} <br />
								f. {item.answer_f_uz_cyrl} <br /> <br />
								{item.comment_uz_cyrl}
							</div>
						))
						} */}
					</div>
				</div>
			</div>
		</>
	)
}

export default Test