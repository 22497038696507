import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Modal } from 'react-bootstrap'
import MaskedInput from 'react-text-mask'
import Select from 'react-select'

import { DELETE, GET, POST, PUT } from 'helpers/api';
import { formatDate, formatMoneyWithCommas, formatTime } from 'helpers/helpers';
import { UPDATE_SHIFT } from 'store/report/actions'
import useDidMountEffect from 'pages/parts/useDidMountEffect'

function FuelIn({ showModal, setShowModal }) {
	const { t } = useTranslation()

	const dispatch = useDispatch()

	const reduxReportData = useSelector(state => state.report)
	const reduxSettings = useSelector(state => state.settings)
	const inputRefs = useRef({
		'oil_storage_id': null
	});

	const shiftData = reduxReportData.reportData?.shift?.opened_at ? formatDate(new Date(reduxReportData.reportData?.shift?.opened_at)) : formatDate(new Date())

	const [showSettingsModal, setShowSettingsModal] = useState(false)

	const [data, setData] = useState({
		'serial': reduxSettings.serial,
		'tin': '',
		'receipt_date': shiftData ? shiftData : '',
		'date_tin': shiftData ? shiftData : '',
		'car_id': '',
		'car_amount': '',
		'tank_volume': '',
		'carpool_id': '',
		'distance': '',
		'oil_storage_id': '',
		'fuel_id': '',
		'storage_tank_id': '',
		'mass_liter': '',
		'mass_kg': '',
		'density': '',
		'temperature': '',
		'calculation_type': 1,
		'amount': '',
		'vat': '',
		'note': '',
		'proxy_num': '',
		'proxy_date': shiftData ? shiftData : '',
		'proxy_id': '',
		'arrival_time': formatTime(new Date(), 'HHmm'),
		'leave_time': formatTime(new Date(), 'HHmm'),
	})

	const [offset, setOffset] = useState(0)

	const calculationTypes = [{ id: 1, name: 'Килограм' }, { id: 2, name: t('LITR') }]
	const [carpools, setCarpools] = useState([])
	const [cars, setCars] = useState([])
	const [oilStorages, setOilStorages] = useState([])
	const [fuels, setFuels] = useState([])
	const [tanks, setTanks] = useState([])
	const [proxies, setProxies] = useState([])

	async function createUpdate(e) {
		e.preventDefault();
		var copyData = { ...data }
		if (!copyData.serial) {
			copyData.serial = reduxSettings.serial;
		}
		var response;
		if (copyData.id) {
			response = await PUT('/fuel-in', copyData, { loader: true, toast: true })
		} else {
			response = await POST('/fuel-in', copyData, { loader: true, toast: true })
		}
		if (response) {
			if (copyData.id) {

			} else {
				clearData()
				const form = e.target;
				console.log(form[0]);
				form[0]?.focus();
				dispatch(UPDATE_SHIFT((reduxReportData.updateShift ?? 0) + 1))
			}
			// setShowModal(false)
			// window.location.reload()
		}
	}

	function clearData() {
		setOffset(0)
		setData({
			'prev_fuel_in_id': data.prev_fuel_in_id,
			'serial': reduxSettings.serial ? reduxSettings.serial : '',
			'tin': '',
			'receipt_date': shiftData ? shiftData : '',
			'date_tin': shiftData ? shiftData : '',
			'car_id': '',
			'car_amount': '',
			'tank_volume': '',
			'carpool_id': '',
			'distance': '',
			'oil_storage_id': '',
			'fuel_id': '',
			'storage_tank_id': '',
			'mass_liter': '',
			'mass_kg': '',
			'density': '',
			'temperature': '',
			'calculation_type': 1,
			'amount': '',
			'vat': '',
			'note': '',
			'proxy_num': '',
			'proxy_date': shiftData ? shiftData : '',
			'proxy_id': '',
			'arrival_time': formatTime(new Date(), 'HHmm'),
			'leave_time': formatTime(new Date(), 'HHmm'),
		})
		const form = document.getElementsByTagName('form')[0];
		if (form) {
			form[0]?.focus();
		}
	}

	function handleChangeText(e) {
		var copyData = { ...data }
		copyData[e.target.name] = e.target.value
		setData(copyData)
	}

	function handleChange(e) {
		var copyData = { ...data }
		copyData[e.target.name] = e.target.value.replace(/[^0-9.]/g, '')
		setData(copyData)
	}

	const handleFocus = (event) => event.target.select();

	function handleEnter(e, selectName) {
		if (e.key === "Enter") {
			var copyData = { ...data }
			if (e.target.name === 'mass_kg' || e.target.name === 'mass_liter') {
				copyData['density'] = (Number(copyData['mass_kg'] ?? 0) / Number(copyData['mass_liter'] ?? 0)).toFixed(3)
			}
			if (e.target.name === 'mass_liter') {
				copyData['amount'] = (Number(copyData['car_amount'] ?? 0) * Number(copyData['mass_liter'] ?? 0))
			}
			if (e.target.name === 'mass_liter' || e.target.name === 'density') {
				if (copyData['mass_liter'])
					copyData['mass_kg'] = Math.round(Number(copyData['mass_liter']) * Number(copyData['density']))
				else if (copyData['mass_kg'])
					copyData['mass_liter'] = Math.round(Number(copyData['mass_kg']) / Number(copyData['density']))
			}
			if (inputRefs.current[selectName]) {
				// inputRefs.current[selectName]?.selectOption(inputRefs.current[selectName]?.state?.focusedOption)
				var focusedOption = inputRefs.current[selectName]?.state?.focusedOption
				if (focusedOption?.id && !data['id']) {
					if (selectName === 'oil_storage_id') {
						copyData['oil_storage_id'] = focusedOption.id
						copyData['distance'] = focusedOption.distance
					}
					if (selectName === 'carpool_id') {
						copyData['carpool_id'] = focusedOption.id
						copyData['car_amount'] = focusedOption.price
					}
					if (selectName === 'car_id') {
						copyData['car_id'] = focusedOption.id
						copyData['tank_volume'] = focusedOption.capacity
						copyData['mass_liter'] = focusedOption.capacity
					}
					if (selectName === 'fuel_id') {
						copyData['fuel_id'] = focusedOption.id
						copyData['storage_tank_id'] = focusedOption?.tanks[0]['id']
						setTanks(focusedOption.tanks)
					}
					if (selectName === 'storage_tank_id') {
						copyData['storage_tank_id'] = focusedOption.id
					}
					if (selectName === 'proxy_id') {
						copyData['proxy_id'] = focusedOption.id
					}
					if (selectName === 'calculation_type') {
						copyData['calculation_type'] = focusedOption.id
					}
				}
				// copyData[e.target.name] = inputRefs.current[e.target.name]?.state?.focusedOption?.id
			}
			setData(copyData)
			const form = e.target.form;
			const index = [...form].indexOf(e.target);
			console.log(index + 1);
			setTimeout(() => {
				form[index + 1].focus();
			}, 10);
			e.preventDefault();
		}
	}

	async function paginateFuelIn(type) {
		const response = await GET(`/fuel-in/${data[type]}`)
		if (response['id']) {
			response.receipt_date = formatDate(response.receipt_date)
			response.date_tin = formatDate(response.date_tin)
			var index = fuels.findIndex(item => item.id === response.fuel_id)
			if (index >= 0) {
				setTanks(fuels[index].tanks)
			}
			setData(response)
		}
	}

	async function deleteFuelIn() {
		const response = await DELETE(`/fuel-in/${data.id}`)
		if (response) {
			setOffset(null)
			dispatch(UPDATE_SHIFT((reduxReportData.updateShift ?? 0) + 1))
		}
	}

	function selectCar(option) {
		setData({ ...data, 'car_id': option.id, 'tank_volume': option?.capacity, 'mass_liter': option?.capacity })
	}

	async function getCarpools() {
		const response = await GET('/carpool')
		if (response) {
			setCarpools(response)
		}
	}

	async function getCars() {
		const response = await GET(`/car?carpool_id=${data.carpool_id}`)
		if (response) {
			setCars(response)
		}
	}

	async function getOilStorages() {
		const response = await GET('/oil-storage')
		if (response) {
			setOilStorages(response)
		}
	}

	async function getFuels() {
		const response = await GET('/fuel')
		if (response) {
			setFuels(response)
			setTanks(response[0].tanks)

			// setData({ ...data, fuel_id: response[0]['id'], storage_tank_id: response[0].tanks[0]['id'] })
		}
	}

	async function getProxies() {
		const response = await GET('/proxy')
		if (response) {
			setProxies(response)
		}
	}

	async function getFuelIn() {
		const response = await GET(`/fuel-in/null`)
		if (response['id']) {
			response.receipt_date = formatDate(response.receipt_date)
			response.date_tin = formatDate(response.date_tin)
			var index = fuels.findIndex(item => item.id === response.fuel_id)
			if (index >= 0) {
				setTanks(fuels[index].tanks)
			}
			setData({ ...data, prev_fuel_in_id: response.prev_fuel_in_id })
		}
	}

	useEffect(() => {
		if (showModal) {
			getFuelIn()
			getCarpools()
			getOilStorages()
			getFuels()
			getProxies()
		} else {
			clearData()
		}
	}, [showModal]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (showModal) {
			getCars()
		}
	}, [data.carpool_id]); // eslint-disable-line react-hooks/exhaustive-deps

	useDidMountEffect(() => {
		if (showModal) {
			if (offset || offset === null) {
				getFuelIn()
			}
		}
	}, [offset]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Modal show={showModal} animation={false} size='xl'
				centered dialogClassName="update-modal-width" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<div className="d-flex align-items-center justify-content-between w-100">
						<Modal.Title>{t('FUEL_IN')}</Modal.Title>
						<i className="uil-cog fz-20 cursor me-2" onClick={() => { setShowSettingsModal(true); setShowModal(false) }}></i>
					</div>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="row mb-4">
							<div className="col-md-6">
								<b>{t('CONSIGNMENT_NOTE')}</b>
								<div className="fuel-in-group">
									<div className="form-group me-2">
										<label>{t('SERIAL')} №</label>
										<input type="text" className="form-control" name="serial" value={data.serial}
											onKeyDown={(e) => handleEnter(e)} autoFocus
											onChange={(e) => handleChangeText(e)} />
									</div>
									<div className="form-group">
										<label>{t('TTN')} №</label>
										<input type="text" className="form-control" name="tin" value={data.tin}
											onKeyDown={(e) => handleEnter(e)}
											onChange={(e) => handleChangeText(e)} />
									</div>
								</div>
								<div className="fuel-in-group">
									<div className="form-group me-2">
										<label>{t('RECEIPT_DATE')}</label>
										<MaskedInput
											mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/,]}
											className="form-control"
											placeholder="xx.xx.xxxx"
											guide={true}
											name="receipt_date"
											value={data.receipt_date}
											onChange={(e) => handleChange(e)}
											onKeyDown={(e) => handleEnter(e)}
											onFocus={handleFocus}
										/>
									</div>
									<div className="form-group">
										<label>{t('DATE_ISSUE_TIN')}</label>
										<MaskedInput
											mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/,]}
											className="form-control"
											placeholder="xx.xx.xxxx"
											guide={true}
											name="date_tin"
											value={data.date_tin}
											onChange={(e) => handleChange(e)}
											onKeyDown={(e) => handleEnter(e)}
											onFocus={handleFocus}
										/>
										{/* <DatePicker
											locale='ru'
											selected={data.date_tin}
											className='form-control'
											onKeyDown={(e) => handleEnter(e)}
											onChange={(date) => setData({ ...data, date_tin: date })}
											dateFormat='dd.MM.yyyy'
											disabledKeyboardNavigation
											autoComplete='off'
										/> */}
									</div>
								</div>
								<div className="fuel-in-group">
									<div className="form-group me-2">
										<label>{t('OIL_STORAGE')}</label>
										<Select
											className="me-2"
											classNamePrefix="react-select"
											options={oilStorages}
											value={oilStorages.find(option => option.id === data.oil_storage_id)}
											onChange={(option) => setData({ ...data, 'oil_storage_id': option.id, 'distance': option.distance })}
											placeholder=""
											noOptionsMessage={() => t('LIST_IS_EMPTY')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
											onKeyDown={(e) => handleEnter(e, 'oil_storage_id')}
											ref={(inputRef) => (inputRefs.current['oil_storage_id'] = inputRef)}
											openMenuOnFocus={true}
										/>
									</div>
									<div className="form-group w-50">
										<label>{t('DISTANCE')}</label>
										<input type="text" className="form-control h-100" name="distance" onKeyDown={(e) => handleEnter(e)}
											value={formatMoneyWithCommas(data.distance)} onChange={(e) => handleChange(e)} onFocus={handleFocus} />
									</div>
								</div>
								<div className="fuel-in-group">
									<div className="form-group me-2">
										<label>{t('CARPOOL')}</label>
										<Select
											className="me-2"
											classNamePrefix="react-select"
											options={carpools}
											value={carpools.find(option => option.id === data.carpool_id)}
											onChange={(option) => {
												setData({ ...data, 'carpool_id': option.id, 'car_amount': option.price });
											}}
											placeholder=""
											noOptionsMessage={() => t('LIST_IS_EMPTY')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
											onKeyDown={(e) => handleEnter(e, 'carpool_id')}
											ref={(inputRef) => (inputRefs.current['carpool_id'] = inputRef)}
											openMenuOnFocus={true}
										/>
									</div>
									<div className="form-group w-50">
										<label>{t('AMOUNT')}</label>
										<input type="text" className="form-control h-100" name="car_amount" onKeyDown={(e) => handleEnter(e)}
											value={formatMoneyWithCommas(data.car_amount)} onChange={(e) => handleChange(e)} onFocus={handleFocus} />
									</div>
								</div>
								<div className="fuel-in-group">
									<div className="form-group me-2">
										<label>{t('CAR')}</label>
										<Select
											className="me-2"
											classNamePrefix="react-select"
											options={cars}
											value={cars.find(option => option.id === data.car_id)}
											onChange={(option) => selectCar(option)}
											placeholder=""
											noOptionsMessage={() => t('LIST_IS_EMPTY')}
											getOptionLabel={(option) => option.car_number}
											getOptionValue={(option) => option.id}
											onKeyDown={(e) => handleEnter(e, 'car_id')}
											ref={(inputRef) => (inputRefs.current['car_id'] = inputRef)}
											openMenuOnFocus={true}
										/>
									</div>
									<div className="form-group w-50">
										<label>{t('TANK_VOLUME')}</label>
										<input type="text" className="form-control h-100" name="tank_volume" onKeyDown={(e) => handleEnter(e)}
											value={formatMoneyWithCommas(data.tank_volume)} onChange={(e) => handleChange(e)} onFocus={handleFocus} />
									</div>
								</div>
								<div className="fuel-in-group">
									<div className="form-group me-2">
										<label>{t('FUEL')}</label>
										<Select
											className="me-2"
											classNamePrefix="react-select"
											options={fuels}
											value={fuels.find(option => option.id === data.fuel_id)}
											onChange={(option) => {
												setData({ ...data, 'fuel_id': option.id, storage_tank_id: option?.tanks[0]['id'] })
												setTanks(option.tanks)
											}}
											placeholder=""
											noOptionsMessage={() => t('LIST_IS_EMPTY')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
											onKeyDown={(e) => handleEnter(e, 'fuel_id')}
											ref={(inputRef) => (inputRefs.current['fuel_id'] = inputRef)}
											openMenuOnFocus={true}
										/>
									</div>
									<div className="form-group w-50">
										<label>{t('MASSA')}({t('LITR')})</label>
										<input type="text" className="form-control h-100" name="mass_liter" onKeyDown={(e) => handleEnter(e)}
											value={(data.mass_liter)} onChange={(e) => handleChange(e)} onFocus={handleFocus} />
									</div>
								</div>
								<div className="fuel-in-group">
									<div className="form-group me-2">
										<label>{t('STORAGE_TANK')}</label>
										<Select
											className="me-2"
											classNamePrefix="react-select"
											options={tanks}
											value={tanks.find(option => option.id === data.storage_tank_id)}
											onChange={(option) => setData({ ...data, 'storage_tank_id': option.id })}
											placeholder=""
											noOptionsMessage={() => t('LIST_IS_EMPTY')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
											onKeyDown={(e) => handleEnter(e, 'storage_tank_id')}
											ref={(inputRef) => (inputRefs.current['storage_tank_id'] = inputRef)}
											openMenuOnFocus={true}
										/>
									</div>
									<div className="form-group w-50">
										<label>{t('MASSA')}({t('KG')})</label>
										<input type="text" className="form-control h-100" name="mass_kg" onKeyDown={(e) => handleEnter(e)}
											value={(data.mass_kg)} onChange={(e) => handleChange(e)} onFocus={handleFocus} />
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<b>{t('INFORMATION')}</b>
								<div className="fuel-in-group">
									<div className="form-group w-100 me-2">
										<label>{t('DENSITY')}</label>
										<input type="text" className="form-control h-100" name="density" onKeyDown={(e) => handleEnter(e)}
											value={formatMoneyWithCommas(data.density)} onChange={(e) => handleChange(e)} onFocus={handleFocus} />
									</div>
									<div className="form-group w-100">
										<label>{t('TEMPRATURE')}</label>
										<input type="text" className="form-control h-100" name="temperature" onKeyDown={(e) => handleEnter(e)}
											value={formatMoneyWithCommas(data.temperature)} onChange={(e) => handleChange(e)} onFocus={handleFocus} />
									</div>
								</div>
								<div className="fuel-in-group">
									<div className="form-group w-100 me-2">
										<label>{t('CALCULATION_TYPE')}</label>
										<Select
											className="me-2"
											classNamePrefix="react-select"
											options={calculationTypes}
											value={calculationTypes.find(option => option.id === data.calculation_type)}
											onChange={(option) => setData({ ...data, 'calculation_type': option.id })}
											placeholder=""
											noOptionsMessage={() => t('LIST_IS_EMPTY')}
											getOptionLabel={(option) => option.name}
											getOptionValue={(option) => option.id}
											onKeyDown={(e) => handleEnter(e, 'calculation_type')}
											ref={(inputRef) => (inputRefs.current['calculation_type'] = inputRef)}
											openMenuOnFocus={true}
										/>
									</div>
									<div className="form-group w-50">
										<label>{t('AMOUNT')}</label>
										<input type="text" className="form-control h-100" name="amount" onKeyDown={(e) => handleEnter(e)}
											value={formatMoneyWithCommas(data.amount)} onChange={(e) => handleChange(e)} />
									</div>
								</div>
								<div className="fuel-in-group">
									<div className="form-group w-100 me-2">
										<label>{t('VAT')}</label>
										<input type="text" className="form-control h-100" name="vat" onKeyDown={(e) => handleEnter(e)}
											value={formatMoneyWithCommas(data.vat)} onChange={(e) => handleChange(e)} />
									</div>
									<div className="form-group w-100">
										<label>{t('NOTE')}</label>
										<input type="text" className="form-control h-100" name="note" onKeyDown={(e) => handleEnter(e)}
											value={data.note} onChange={(e) => handleChangeText(e)} />
									</div>
								</div>
								<div className="fuel-in-group border-top border-bottom">
									<b>{t('PROXY')}</b>
								</div>
								<div className="fuel-in-group">
									<div className="form-group w-100 me-2">
										<label>№</label>
										<input type="text" className="form-control h-100" name="proxy_num" onKeyDown={(e) => handleEnter(e)}
											value={formatMoneyWithCommas(data.proxy_num)} onChange={(e) => handleChange(e)} />
									</div>
									<div className="form-group w-100">
										<label>{t('DATE')}</label>
										<MaskedInput
											mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/,]}
											className="form-control"
											placeholder="xx.xx.xxxx"
											guide={true}
											name="proxy_date"
											value={data.proxy_date}
											onChange={(e) => handleChange(e)}
											onKeyDown={(e) => handleEnter(e)}
											onFocus={handleFocus}
										/>
									</div>
								</div>
								<div className="form-group w-100">
									<label>{t('FIO')}</label>
									<Select
										className="me-2"
										classNamePrefix="react-select"
										options={proxies}
										value={proxies.find(option => option.id === data.proxy_id)}
										onChange={(option) => setData({ ...data, 'proxy_id': option.id })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.id}
										onKeyDown={(e) => handleEnter(e, 'proxy_id')}
										ref={(inputRef) => (inputRefs.current['proxy_id'] = inputRef)}
										openMenuOnFocus={true}
									/>
								</div>
								<div className="fuel-in-group">
									<div className="form-group w-100 me-2">
										<label>{t('ARRIVAL')}</label>
										<MaskedInput
											mask={[/[0-9]/, /\d/, ':', /\d/, /\d/]}
											className="form-control"
											placeholder="xx.xx"
											guide={true}
											name="arrival_time"
											value={data.arrival_time}
											onChange={(e) => handleChange(e)}
											onKeyDown={(e) => handleEnter(e)}
											onFocus={handleFocus}
										/>
									</div>
									<div className="form-group w-100">
										<label>{t('LEAVE')}</label>
										<MaskedInput
											mask={[/[0-9]/, /\d/, ':', /\d/, /\d/]}
											className="form-control"
											placeholder="xx.xx"
											guide={true}
											name="leave_time"
											value={data.leave_time}
											onChange={(e) => handleChange(e)}
											onKeyDown={(e) => e.key === 'Enter' ? createUpdate(e) : null}
											onFocus={handleFocus}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="buttons__container second">
							<button className="w-100" type="button" onClick={() => paginateFuelIn('prev_fuel_in_id')} disabled={!data.prev_fuel_in_id}>
								<i className="uil uil-arrow-circle-left"></i>
								<div>{t('BACK')}</div>
							</button>
							<button className="w-100" type="button" onClick={() => paginateFuelIn('next_fuel_in_id')} disabled={!data.next_fuel_in_id}>
								<i className="uil uil-arrow-circle-right"></i>
								<div>{t('FORWARD')}</div>
							</button>
							<button className="w-100" type="button" onClick={() => clearData()} disabled={!data.id}>
								<i className="uil-plus"></i>
								<div>{t('CREATE')}</div>
							</button>
							<button className="w-100" onClick={() => deleteFuelIn()} disabled={!data.id}>
								<i className="uil uil-trash-alt"></i>
								<div>{t('DELETE')}</div>
							</button>
							<button className="w-100" type="button" onClick={() => setShowModal(false)}>
								<i className="uil uil-ban"></i>
								<div>{t('CLOSE')}</div>
							</button>
							<button type='submit' className="w-100">
								<i className="uil uil-save"></i>
								<div>{t('SAVE')}</div>
							</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>

			<Modal show={showSettingsModal} animation={false} size='md'
				centered dialogClassName="update-modal-width" onHide={() => { setShowSettingsModal(false); setShowModal(true) }}>
				<Modal.Header closeButton>
					<div className="d-flex align-items-center justify-content-between w-100">
						<h4>{t('SETTINGS')}</h4>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="card">
						<div className="card-body p-0">
							<div className="row mb-3">
								<b>{t('FUEL_IN')}</b>
								<div className="col-md-6">
									<div className="form-group">
										<label>{t('SERIAL')} №</label>
										<input type="text" className="form-control" value={reduxSettings.serial}
											onChange={(e) => dispatch({ type: 'SET_SETTINGS', payload: { ...reduxSettings, serial: e.target.value } })} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				{/* <Modal.Footer>
					<div className="d-flex justify-content-end w-100">
						<button className="btn btn-primary w-25" onClick={() => saveSettings()}>
							{t('SAVE')}
						</button>
					</div>
				</Modal.Footer> */}
			</Modal>
		</>
	)
}

export default FuelIn