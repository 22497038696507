import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import Select from 'react-select'
import readXlsxFile from 'read-excel-file'
import XLSX from 'xlsx'

import { formatDate } from 'helpers/helpers'
import { DELETE, GET, POST, PUT } from 'helpers/api'
import DeleteModal from "pages/parts/DeleteModal"
import { toast } from "react-toastify"

function Index({ showModal, setShowModal, id }) {
	const { t } = useTranslation()

	const [selectedItem, setSelectedItem] = useState({
		'storage_tank_id': '',
		'height': '',
		'capacity': '',
		'density': '',
	})
	const [tanks, setTanks] = useState([])
	const [data, setData] = useState([])
	const [hideModal, setHideModal] = useState(false)
	const [showCreateModal, setShowCreateModal] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	async function createUpdate(e, edit = false) {
		e.preventDefault();
		var selectedItemCopy = { ...selectedItem }
		if (!selectedItemCopy.id) {
			await POST('/calibration', selectedItemCopy)
		} else {
			await PUT('/calibration', selectedItemCopy, { loader: true })
		}

		if (edit) {
			var index = data.findIndex(item => item.id === selectedItem.id)
			if (index >= 0) {
				var dataCopy = [...data]
				dataCopy[index] = selectedItem
				setData(dataCopy)
			}
			setSelectedItem({})
		} else {
			setSelectedItem({})
			setHideModal(true)
			setShowCreateModal(false)
		}
	}

	const handleUpload = (e) => {
		if (e?.target?.files[0]) {
			var arr = []
			readXlsxFile(e.target.files[0]).then(async (rows) => {
				try {
					for (let i = 1; i < rows?.length; i++) {
						arr.push({
							'storage_tank_id': id,
							'height': rows[i][0],
							'capacity': rows[i][1],
							'density': rows[i][2],
						})
					}
					if (arr?.length) {
						const response = await POST('/calibration-excel', arr);
						if (response) {
							getData()
						}
					}
				} catch (error) {
					toast.error(error)
				}
			})
		}
	}

	async function deleteItem() {
		if (selectedItem.id) {
			var selectedItemCopy = { ...selectedItem }
			await DELETE('/calibration/' + selectedItemCopy.id, {})
			setSelectedItem({})
			setShowDeleteModal(false)
			getData()
		} else {
			await DELETE('/calibrations/' + id, {})
			setShowDeleteModal(false)
			getData()
		}
	}

	function handleInputChange(e) {
		var selectedItemCopy = { ...selectedItem };
		selectedItemCopy[e.target.name] = e.target.value
		setSelectedItem(selectedItemCopy)
	}

	function selectedItemEdit(item, bool) {
		var selectedItemCopy = { ...item };

		selectedItemCopy.isEdit = bool;

		setSelectedItem(selectedItemCopy);
	}

	function openModal(item) {
		var selectedItemCopy = { ...item }
		setSelectedItem(selectedItemCopy)
		setHideModal(true)
		setShowCreateModal(true)
	}

	function openDeleteModal(item) {
		var selectedItemCopy = { ...item }
		setSelectedItem({ id: selectedItemCopy.id })
		setShowDeleteModal(true)
	}

	function closeModal() {
		setSelectedItem({
			'storage_tank_id': '',
			'height': '',
			'capacity': '',
			'density': '',
		})
		setHideModal(false)
		setShowCreateModal(false)
	}

	function donwloadExcel() {
		var excelHeaders = [[t('SM'), t('LITR'), t('MM'), t('STORAGE_TANK'),]]
		for (let i = 0; i < data.length; i++) {
			var item = data[i]
			var excelItems = []
			excelItems.push(item.height)
			excelItems.push(item.capacity)
			excelItems.push(item.density)
			excelItems.push(item.tank_name)
			excelHeaders.push(excelItems)
		}
		const ws = XLSX.utils.aoa_to_sheet(excelHeaders);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		XLSX.writeFile(wb, t('CALIBRATION') + '-' + data[0]['tank_name'] + ".xlsx");
	}

	async function getData() {
		const response = await GET(`/calibration?storage_tank_id=${id}`, {}, { loader: true })
		if (response) {
			setData(response)
			setSelectedItem({ ...selectedItem, storage_tank_id: id })
		}
	}

	async function getTanks() {
		const response = await GET('/storage-tank-helper')
		if (response) {
			setTanks(response)
		}
	}

	useEffect(() => {
		if (showModal) {
			getData()
			getTanks()
		}
	}, [showModal]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			{!hideModal &&
				<Modal show={showModal} animation={false} size='xl'
					centered dialogClassName="update-modal-width" onHide={() => setShowModal(false)}>
					<Modal.Header closeButton>
						<div className="d-flex align-items-center justify-content-between w-100">
							<h4>{t('CALIBRATION')}</h4>
							<div className="d-flex justify-content-end align-items-center">
								<div className="con-upload me-3">
									<div className="con-img-upload">
										<div className="con-input-upload">
											<input type="file" className="form-control" onChange={(e) => handleUpload(e)} />
											<span>Загрузите Excel</span>
										</div>
									</div>
								</div>
								<button onClick={() => donwloadExcel()} className="btn-rounded btn btn-primary h-100 me-3">
									<div className="upload-from-excel">
										{t('DOWNLOAD')} EXCEL
									</div>
								</button>
								<div className="btn-rounded btn btn-primary h-100 me-3">
									<a href="/example.xlsx" className="upload-from-excel">
										{t('DOWNLOAD_TEMPLATE')}
									</a>
								</div>
								<button className="btn-rounded btn btn-primary d-flex w-100 me-2" onClick={() => openModal()}>
									<i className="uil uil-plus me-3"></i>
									{t('CREATE')}
								</button>
								<button className="btn-rounded btn btn-danger w-100 me-2" onClick={() => openDeleteModal()}>
									{t('DELETE_ALL')}
								</button>
							</div>
						</div>
					</Modal.Header>
					<Modal.Body>
						<div className="card">
							<div className="card-body p-0">
								<div className="table-responsive">
									<table className="table table-striped mb-0">
										<thead>
											<tr>
												<th className="text-center">{t('SM')}</th>
												<th className="text-center">{t('LITR')}</th>
												<th className="text-center">{t('MM')}</th>
												<th className="text-center">{t('STORAGE_TANK')}</th>
												<th className="text-center">{t('DATE')}</th>
												<th className="text-center">{t('ACTION')}</th>
											</tr>
										</thead>
										<tbody>
											{data?.map((item, index) => (
												selectedItem.id === item.id && selectedItem.isEdit ?
													<tr key={index}>
														<td className="text-center">
															<input type="number" step="0.01" min="0" className={"table-input " + ((Number(item.height)) && "required-border")}
																name="height" value={selectedItem.height} onChange={(e) => handleInputChange(e)} />
														</td>
														<td className="text-center">
															<input type="number" step="0.01" min="0" className={"table-input " + ((Number(item.capacity)) && "required-border")}
																name="capacity" value={selectedItem.capacity} onChange={(e) => handleInputChange(e)} />
														</td>
														<td className="text-center">
															<input type="number" step="0.01" min="0" className={"table-input " + ((Number(item.density)) && "required-border")}
																name="density" value={selectedItem.density} onChange={(e) => handleInputChange(e)} />
														</td>
														<td className="text-center">{item.tank_name}</td>
														<td className="text-center">{formatDate(item.created_at)}</td>
														<td className="d-flex justify-content-center">
															<div className="table-action-button table-action-primary-button me-2" onClick={(e) => createUpdate(e, true)}>
																<i className="uil-check"></i>
															</div>
															<div className="table-action-button table-action-danger-button" onClick={() => selectedItemEdit(item, false)}>
																<i className="uil-times"></i>
															</div>
														</td>
													</tr>
													:
													<tr key={index}>
														<td className="text-center">{item.height}</td>
														<td className="text-center">{item.capacity}</td>
														<td className="text-center">{item.density}</td>
														<td className="text-center">{item.tank_name}</td>
														<td className="text-center">{formatDate(item.created_at)}</td>
														<td className="d-flex justify-content-center">
															<div className="table-action-button table-action-primary-button me-2" onClick={() => selectedItemEdit(item, true)}>
																<i className="uil-edit-alt"></i>
															</div>
															<div className="table-action-button table-action-danger-button" onClick={() => openDeleteModal(item)}>
																<i className="uil-trash-alt"></i>
															</div>
														</td>
													</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</Modal.Body>
				</Modal>}

			{/* MODAL */}
			<Modal show={showCreateModal} animation={false} centered dialogClassName="update-modal-width" onHide={() => closeModal()}>
				<Modal.Header closeButton>
					<Modal.Title>
						{!selectedItem.id ?
							<span>{t('CREATE')}</span>
							:
							<span>{t('EDIT')}</span>
						}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={(e) => createUpdate(e)} autoComplete="off">
						<div className="form-group">
							<label>{t('HEIGHT')}</label>
							<input type="number" className="form-control" name="height" value={selectedItem.height}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'height': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('CAPACITY')}</label>
							<input type="number" className="form-control" name="capacity" value={selectedItem.capacity}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'capacity': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('DENSITY')}</label>
							<input type="number" className="form-control" name="density" value={selectedItem.density}
								onChange={(e) => setSelectedItem({ ...selectedItem, 'density': e.target.value })} />
						</div>
						<div className="form-group">
							<label>{t('STORAGE_TANK')}</label>
							<Select
								options={tanks}
								value={tanks.find(option => option.id === selectedItem.storage_tank_id)}
								onChange={(option) => setSelectedItem({ ...selectedItem, 'storage_tank_id': option.id })}
								placeholder=""
								noOptionsMessage={() => t('LIST_IS_EMPTY')}
								getOptionLabel={(option) => option.name}
								getOptionValue={(option) => option.id}
							/>
						</div>
						<div className="d-flex w-100 mt-3">
							<button type="button" className="btn btn-outline-warning w-100 me-2" onClick={() => closeModal()}>{t('CANCEL')}</button>
							<button type="submit" className="btn btn-primary w-100">{t('SAVE')}</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
			{/* MODAL */}

			<DeleteModal
				modalDelete={showDeleteModal}
				setModalDelete={setShowDeleteModal}
				deleteItem={deleteItem}>
			</DeleteModal>
		</>
	)
}

export default Index