import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'

import { Modal } from 'react-bootstrap'
import Select from 'react-select'

import { GET, POST } from 'helpers/api'
import useDidMountEffect from 'pages/parts/useDidMountEffect'

function Setting({ showModal, setShowModal }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [data, setData] = useState({
		payme: {},
		click: {},
		uzum: {},
	})

	const losses = [
		{ name: 'Над земный летний (0.145)', value: 0.145 },
		{ name: 'Над земный зимний (0.1)', value: 0.1 },
		{ name: 'Под земный летний (0.088)', value: 0.088 },
		{ name: 'Под земный зимний (0.07)', value: 0.07 },
	]

	const roundings = [
		{ name: 'По умолчанию', value: 'round' },
		{ name: 'В большую сторону', value: 'ceil' },
		{ name: 'В меньшую сторону', value: 'floor' },
	]

	const autoUpdates = [
		{ name: 'Оключить', value: 0 },
		{ name: 'После изменения', value: 1 },
		{ name: 'Каждые 30 сек', value: 2, disabled: true },
		{ name: 'Каждую минуту', value: 3, disabled: true },
	]

	const reduxSettings = useSelector(state => state.settings)

	async function saveSettings() {
		const response = await POST('/pos-settings', {
			settings: JSON.stringify(data)
		})
		if (response) {
			dispatch({ type: 'SET_SETTINGS', payload: data })
		}
	}

	function handleInput(type, e) {
		var dataCopy = { ...data }
		dataCopy[type][e.target.name] = e.target.value
		setData(dataCopy)
	}

	async function getData() {
		const response = await GET('/pos', {}, { loader: true })
		var dataCopy = { ...reduxSettings }
		if (response.settings) {
			dataCopy = { ...JSON.parse(response.settings) }
		} else {
			dataCopy = { ...reduxSettings }
		}
		if (!dataCopy.payme) {
			dataCopy.payme = {
				merchant_id: '',
				merchant_secret_key: '',
			}
		}
		if (!dataCopy.click) {
			dataCopy.click = {
				merchant_id: '',
				merchant_secret_key: '',
				merchant_service_id: '',
				merchant_service_user_id: '',
			}
		}
		console.log(dataCopy);
		setData(dataCopy)
	}

	useDidMountEffect(() => {
		if (showModal) {
			getData()
		}
	}, [showModal]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Modal show={showModal} animation={false} size='lg'
				centered dialogClassName="update-modal-width" onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<div className="d-flex align-items-center justify-content-between w-100">
						<h4>{t('SETTINGS')}</h4>
					</div>
				</Modal.Header>
				<Modal.Body>
					<div className="card">
						<div className="card-body p-0">
							<div className="d-flex align-items-center justify-content-between mb-3">
								<div>
									<b>{t('LOSES')}</b>
								</div>
								<div className="w-50">
									<Select
										options={losses}
										value={losses.find(option => option.value === data.loss)}
										onChange={(option) => setData({ ...data, 'loss': option.value })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.value}
									/>
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-between mb-3">
								<div>
									<b>{t('rounding')}</b>
								</div>
								<div className="w-50">
									<Select
										options={roundings}
										value={roundings.find(option => option.value === data.rounding)}
										onChange={(option) => setData({ ...data, 'rounding': option.value })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.value}
									/>
								</div>
							</div>
							<div className="d-flex align-items-center justify-content-between mb-3">
								<div>
									<b>{t('auto_update')}</b>
								</div>
								<div className="w-50">
									<Select
										options={autoUpdates}
										value={autoUpdates.find(option => option.value === data.auto_update)}
										onChange={(option) => setData({ ...data, 'auto_update': option.value })}
										placeholder=""
										noOptionsMessage={() => t('LIST_IS_EMPTY')}
										getOptionLabel={(option) => option.name}
										getOptionValue={(option) => option.value}
										isOptionDisabled={(option) => option.disabled}
									/>
									{/* <div className="form-check form-switch">
										<input type="checkbox" className="ios-switch light" checked={data.auto_update}
											onChange={(e) => setData({ ...data, 'auto_update': e.target.checked })} />
									</div> */}
								</div>
							</div>

							<div className="d-flex align-items-center justify-content-between mb-3">
								<div>
									<b>{t('vat')}</b>
								</div>
								<div className="w-25">
									<input type="text" className="form-control text-end" value={data.vat}
										onChange={(e) => setData({ ...data, vat: Number(e.target.value) > 12 ? '12' : e.target.value })} />
								</div>
							</div>

							<div className="mb-3">
								<b>{t('payme')}</b>
								<div className="d-flex align-items-center gap-3">
									<div className="w-100">
										<label>merchant_id</label>
										<input type="text" className="form-control" name="merchant_id"
											value={data.payme.merchant_id} onChange={(e) => handleInput('payme', e)} />
									</div>
									<div className="w-100">
										<label>merchant_secret_key</label>
										<input type="text" className="form-control" name="merchant_secret_key"
											value={data.payme.merchant_secret_key} onChange={(e) => handleInput('payme', e)} />
									</div>
								</div>
							</div>

							<div className="mb-3">
								<b>Click</b>
								<div className="d-flex align-items-center gap-3">
									<div className="w-100">
										<label>merchant_id</label>
										<input type="text" className="form-control" name="merchant_id"
											value={data.click.merchant_id} onChange={(e) => handleInput('click', e)} />
									</div>
									<div className="w-100">
										<label>merchant_secret_key</label>
										<input type="text" className="form-control" name="merchant_secret_key"
											value={data.click.merchant_secret_key} onChange={(e) => handleInput('click', e)} />
									</div>
									<div className="w-100">
										<label>merchant_service_id</label>
										<input type="text" className="form-control" name="merchant_service_id"
											value={data.click.merchant_service_id} onChange={(e) => handleInput('click', e)} />
									</div>
									<div className="w-100">
										<label>merchant_service_user_id</label>
										<input type="text" className="form-control" name="merchant_service_user_id"
											value={data.click.merchant_service_user_id} onChange={(e) => handleInput('click', e)} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<button className="btn btn-primary w-100" onClick={() => saveSettings()}>
						{t('SAVE')}
					</button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default Setting