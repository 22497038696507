import React, { useEffect, useState } from 'react'

import questions_ru from './question_uzk.json';
import questions from './questions.json';
import Header from './Header';
import { toast } from 'react-toastify';

function Test() {
	const [comments, setComments] = useState(questions)

	async function showFile(e) {
		if (e.target) {
			e.preventDefault();
			var commentsCopy = [...comments];
			var files = [...e.target.files];
			files.sort((a, b) => {
				let numA = parseInt(a.name.match(/_(\d+)\.txt/)[1], 10);
				let numB = parseInt(b.name.match(/_(\d+)\.txt/)[1], 10);

				return numB - numA;
			});

			console.log(Math.ceil((commentsCopy.length + 1) / 10) + 70);
			console.log(files);
			for (let i = 0; i < files.length; i++) {
				const file = files[i]
				console.log(file.name);

				await new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onload = (e) => {
						let text = e.target.result;
						const parts = {};
						const regexes = [
							{ key: 'question', regex: /^(.*?)F1/s },
							{ key: 'answer_a_ru', regex: /F1(.*?)(F2|Комментарий)/s },
							{ key: 'answer_b_ru', regex: /F2(.*?)(F3|Комментарий)/s },
							{ key: 'answer_c_ru', regex: /F3(.*?)(F4|Комментарий)/s },
							{ key: 'answer_d_ru', regex: /F4(.*?)(F5|Комментарий)/s },
							{ key: 'answer_f_ru', regex: /F5(.*?)(Комментарий)/s },
							{ key: 'comment', regex: /Комментарий:(.*)/s }
						];

						regexes.forEach(({ key, regex }) => {
							const match = text.match(regex);
							parts[key] = match ? match[1].trim() : '';
						});

						commentsCopy.push({
							"question_ru": parts.question,
							"answer_a_ru": parts.answer_a_ru,
							"answer_b_ru": parts.answer_b_ru,
							"answer_c_ru": parts.answer_c_ru,
							"answer_d_ru": parts.answer_d_ru,
							"answer_f_ru": parts.answer_f_ru,
							"comment_ru": parts.comment,
							"row_number": commentsCopy.length + 1 + 700,
							"bilet_number": Math.ceil((commentsCopy.length + 1) / 10) + 70,
							"correct_answer": "",
							"is_new": true,
						});

						resolve();
					};
					reader.onerror = reject;
					reader.readAsText(file);
				});
			}

			e.target.value = null;
			toast.success(commentsCopy[commentsCopy.length - 1]['bilet_number']);
			setComments(commentsCopy);
		}
	}

	const downloadJSON = () => {
		const dataStr = JSON.stringify(comments, null, 2);
		const blob = new Blob([dataStr], { type: "application/json" });
		const url = URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = url;
		link.download = 'comments.json';
		link.click();

		URL.revokeObjectURL(url);
	};

	useEffect(() => {
		var prefix = 'uz_cyrl'
		var commentsCopy = [...comments]
		var questionsRu = [...questions_ru.questionnaires]

		for (let i = 0; i < questionsRu.length; i++) {
			if (i === 0) {
				console.log(questionsRu[i]);
			}
			commentsCopy[i]['question_' + prefix] = questionsRu[i].question
			commentsCopy[i]['comment_' + [prefix]] = questionsRu[i].correct_ans_alls

			// if (questionsRu[i].correct_answer === 1) {
			// 	commentsCopy[i]['correct_answer'] = "A"
			// }
			// if (questionsRu[i].correct_answer === 2) {
			// 	commentsCopy[i]['correct_answer'] = "B"
			// }
			// if (questionsRu[i].correct_answer === 3) {
			// 	commentsCopy[i]['correct_answer'] = "C"
			// }
			// if (questionsRu[i].correct_answer === 4) {
			// 	commentsCopy[i]['correct_answer'] = "D"
			// }
			// if (questionsRu[i].correct_answer === 5) {
			// 	commentsCopy[i]['correct_answer'] = "F"
			// }
			questionsRu[i].answers = questionsRu[i].answers.map(str => str.replace(/^[A-E]\)\s/, ''))
			if (i === 0) {
				console.log(questionsRu[i].answers);
			}
			for (let j = 0; j < questionsRu[i].answers.length; j++) {
				if (j === 0) {
					commentsCopy[i]['answer_a_' + prefix] = questionsRu[i].answers[j]
				}
				if (j === 1) {
					commentsCopy[i]['answer_b_' + prefix] = questionsRu[i].answers[j]
				}
				if (j === 2) {
					commentsCopy[i]['answer_c_' + prefix] = questionsRu[i].answers[j]
				}
				if (j === 3) {
					commentsCopy[i]['answer_d_' + prefix] = questionsRu[i].answers[j]
				}
				if (j === 4) {
					commentsCopy[i]['answer_f_' + prefix] = questionsRu[i].answers[j]
				}
			}
		}

		console.log(commentsCopy);

		setComments(commentsCopy)
	}, []);

	return (
		<>
			<div className="container">
				<Header />

				<div className="text-content">
					<input type="file" accept="text/plain" className="form-control" multiple onChange={(e) => showFile(e)} />

					<button className="btn btn-primary my-2 w-100" onClick={downloadJSON}>Download JSON</button>

					<div style={{ overflowY: 'auto', height: '75vh' }}>
						{comments.map((item, index) => (
							<div key={index} className="my-2">
								{item.row_number} ({item.bilet_number})
								<b> {item.question_ru}</b> <br /> <br />
								a. {item.answer_a_ru} <br />
								b. {item.answer_b_ru} <br />
								c. {item.answer_c_ru} <br />
								d. {item.answer_d_ru} <br />
								f. {item.answer_f_ru} <br /> <br />
								{item.comment_ru}
							</div>
						))
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default Test