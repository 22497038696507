import React, { useState, useRef } from 'react'

// import questions from './questions.json';
import questions from './test.json';
import { toast } from 'react-toastify';
import Header from './Header';

function Test() {
	const [comments, setComments] = useState(questions.filter(item => (item.bilet_number > 70)))
	const commentsEndRef = useRef(null);

	async function showFile(e) {
		if (e.target) {
			e.preventDefault();
			var commentsCopy = [...comments];
			console.log(e.target.files);

			var files = [...e.target.files];
			files.sort((a, b) => {
				let numA = parseInt(a.name.match(/_(\d+)\.txt/)[1], 10);
				let numB = parseInt(b.name.match(/_(\d+)\.txt/)[1], 10);

				return numB - numA;
			});

			console.log(Math.ceil((commentsCopy.length + 1) / 10) + 70);
			console.log(files);
			for (let i = 0; i < files.length; i++) {
				const file = files[i]
				console.log(file.name);

				await new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onload = (e) => {
						let text = e.target.result;
						var commentsLength = commentsCopy.filter(item => (item.bilet_number > 70 && item.answer_a_uz_latn)).length;

						const parts = {};
						const regexes = [
							{ key: 'question', regex: /^(.*?)F1/s },
							{ key: 'answer_a_uz_latn', regex: /F1(.*?)(F2|Izoh)/s },
							{ key: 'answer_b_uz_latn', regex: /F2(.*?)(F3|Izoh)/s },
							{ key: 'answer_c_uz_latn', regex: /F3(.*?)(F4|Izoh)/s },
							{ key: 'answer_d_uz_latn', regex: /F4(.*?)(F5|Izoh)/s },
							{ key: 'answer_f_uz_latn', regex: /F5(.*?)(Izoh)/s },
							{ key: 'comment', regex: /Izoh:(.*)/s }
						];

						regexes.forEach(({ key, regex }) => {
							const match = text.match(regex);
							parts[key] = match ? match[1].trim() : '';
						});

						commentsCopy[commentsLength] = {
							...commentsCopy[commentsLength],
							"question_uz_latn": parts.question,
							"answer_a_uz_latn": parts.answer_a_uz_latn,
							"answer_b_uz_latn": parts.answer_b_uz_latn,
							"answer_c_uz_latn": parts.answer_c_uz_latn,
							"answer_d_uz_latn": parts.answer_d_uz_latn,
							"answer_f_uz_latn": parts.answer_f_uz_latn,
							"comment_uz_latn": parts.comment,
							"correct_answer": "",
						}

						text = text.replace(/^[ -]+|[ -]+$/g, '');
						text = text.replace(/No/g, '№');
						text = text.replace(/"/g, '\\"');
						text = text.replace(/\n/g, ' ');
						var index = questions.findIndex(item => item.id === commentsCopy.length + 1);
						if (index !== -1) {
							commentsCopy.push({
								...questions[index],
								"comment_uz_latn": text,
							});
						}

						resolve();
					};
					reader.onerror = reject;
					reader.readAsText(file);
				});
			}

			e.target.value = null;
			var commentsLength = commentsCopy.filter(item => (item.bilet_number > 70 && item.answer_a_uz_latn)).length;
			toast.success(commentsCopy[commentsLength - 1]?.bilet_number);

			setComments(commentsCopy);
		}
	}

	const downloadJSON = () => {
		const dataStr = JSON.stringify(comments, null, 2);
		const blob = new Blob([dataStr], { type: "application/json" });
		const url = URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = url;
		link.download = 'comments.json';
		link.click();

		URL.revokeObjectURL(url);
	};

	// useEffect(() => {
	// 	var commentsCopy = [...comments];
	// 	var commentsCopy2 = [...comments];

	// 	// Сохраняем оригинальные копии билетов 93 и 94
	// 	var bilet93 = [...commentsCopy.filter(item => item.bilet_number === 93)];
	// 	var bilet94 = [...commentsCopy.filter(item => item.bilet_number === 94)];

	// 	// Сделаем полные копии данных, чтобы избежать изменений в процессе
	// 	var biletCopy93 = bilet93.map(item => ({ ...item }));
	// 	var biletCopy94 = bilet94.map(item => ({ ...item }));



	// 	for (let i = 0; i < bilet93.length; i++) {
	// 		bilet93[i]['question_uz_latn'] = biletCopy94[i]['question_uz_latn']
	// 		bilet93[i]['question_uz_cyrl'] = biletCopy94[i]['question_uz_cyrl']
	// 		bilet93[i]['answer_a_uz_latn'] = biletCopy94[i]['answer_a_uz_latn']
	// 		bilet93[i]['answer_b_uz_latn'] = biletCopy94[i]['answer_b_uz_latn']
	// 		bilet93[i]['answer_c_uz_latn'] = biletCopy94[i]['answer_c_uz_latn']
	// 		bilet93[i]['answer_d_uz_latn'] = biletCopy94[i]['answer_d_uz_latn']
	// 		bilet93[i]['answer_f_uz_latn'] = biletCopy94[i]['answer_f_uz_latn']
	// 		bilet93[i]['comment_uz_latn'] = biletCopy94[i]['comment_uz_latn']

	// 		var index = commentsCopy.findIndex(item => item.row_number === bilet93[i]['row_number']);
	// 		commentsCopy2[index] = bilet93[i]
	// 	}

	// 	for (let i = 0; i < bilet94.length; i++) {
	// 		bilet94[i]['question_uz_latn'] = biletCopy93[i]['question_uz_latn']
	// 		bilet94[i]['question_uz_cyrl'] = biletCopy93[i]['question_uz_cyrl']
	// 		bilet94[i]['answer_a_uz_latn'] = biletCopy93[i]['answer_a_uz_latn']
	// 		bilet94[i]['answer_b_uz_latn'] = biletCopy93[i]['answer_b_uz_latn']
	// 		bilet94[i]['answer_c_uz_latn'] = biletCopy93[i]['answer_c_uz_latn']
	// 		bilet94[i]['answer_d_uz_latn'] = biletCopy93[i]['answer_d_uz_latn']
	// 		bilet94[i]['answer_f_uz_latn'] = biletCopy93[i]['answer_f_uz_latn']
	// 		bilet94[i]['comment_uz_latn'] = biletCopy93[i]['comment_uz_latn']

	// 		var index = commentsCopy.findIndex(item => item.row_number === bilet94[i]['row_number']);

	// 		commentsCopy2[index] = bilet94[i]
	// 	}

	// 	console.log(commentsCopy2.filter(item => item.bilet_number === 93));
	// 	console.log(commentsCopy2.filter(item => item.bilet_number === 94));
	// 	setComments(commentsCopy2)
	// }, [])


	return (
		<>
			<div className="container">
				<Header />

				<div className="text-content">
					<input type="file" accept="text/plain" className="form-control" multiple onChange={(e) => showFile(e)} />

					<button className="btn btn-primary my-2 w-100" onClick={downloadJSON}>Download JSON</button>

					<div style={{ overflowY: 'auto', height: '80vh' }}>
						{comments.map((item, index) => (
							<div key={index} className="my-2">
								{item.row_number} ({item.bilet_number})
								<b> {item.question_uz_latn}</b> <br /> <br />
								a. {item.answer_a_uz_latn} <br />
								b. {item.answer_b_uz_latn} <br />
								c. {item.answer_c_uz_latn} <br />
								d. {item.answer_d_uz_latn} <br />
								f. {item.answer_f_uz_latn} <br /> <br />
								{item.comment_uz_latn}
								<div ref={commentsEndRef} ></div>
							</div>
						))
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default Test