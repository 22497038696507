import React, { useState, useEffect } from 'react'

// import questions from './questions.json';
import jsonQuestions from './questions.json';
import Header from './Header';

function Test() {
	const [questions] = useState([...jsonQuestions])
	const comments = [
		11,
		12,
		13,
		14,
		15,
	]

	const downloadJSON = () => {
		const dataStr = JSON.stringify(comments, null, 2);
		const blob = new Blob([dataStr], { type: "application/json" });
		const url = URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = url;
		link.download = 'comments.json';
		link.click();

		URL.revokeObjectURL(url);
	};

	useEffect(() => {
		var commentsCopy = [...comments];

		for (let i = 0; i < commentsCopy.length; i++) {
			var biletsByNumber = questions.filter(item => item.bilet_number === commentsCopy[i])

			for (let j = 0; j < biletsByNumber.length; j++) {
				// biletsByNumber[j]
			}
		}
	}, [])


	return (
		<>
			<div className="container">
				<Header />

				<div className="text-content">
					<button className="btn btn-primary my-2 w-100" onClick={downloadJSON}>Download JSON</button>

					<div style={{ overflowY: 'auto', height: '80vh' }}>
						{comments.map((item, index) => (
							<div key={index} className="my-2">
								{item.row_number} ({item.bilet_number})
								{/* <b> {item.question_uz_latn}</b> <br /> <br />
                                a. {item.answer_a_uz_latn} <br />
                                b. {item.answer_b_uz_latn} <br />
                                c. {item.answer_c_uz_latn} <br />
                                d. {item.answer_d_uz_latn} <br />
                                f. {item.answer_f_uz_latn} <br /> <br /> */}
								{item.comment_ru}
							</div>
						))
						}
					</div>
				</div>
			</div>
		</>
	)
}

export default Test