import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
	return (
		<>
			<div>
				<div className="test-header shadow">
					<Link to="/test-ru">
						<h4>PDD RU</h4>
					</Link>
					<Link to="/test-uz">
						<h4>PDD UZ LATN</h4>
					</Link>
					<Link to="/test-cyrl">
						<h4>PDD UZ CYRL</h4>
					</Link>
					<Link to="/test-image">
						<h4>PDD IMAGE</h4>
					</Link>
					<Link to="/test-comment">
						<h4>PDD Comment</h4>
					</Link>
				</div>
			</div>
		</>
	)
}

export default Header